import langSettings from '../settings/settings.languages';

/*
Images
*/
import Image_120hz from '../assets/images/features/120Hz_Smooth_Display.jpg';
import Image_4k from '../assets/images/features/4K_Video.jpg';
import Image_5g from '../assets/images/features/5G.jpg';
import Image_5YearsUpdates from '../assets/images/features/5Years_Pixel_Updates.jpg';
import Image_AntiVirus from '../assets/images/features/Anti-virus_Anti-phishing.jpg';
import Image_Sensors from '../assets/images/features/Bigger_Sensors.jpg';
import Image_Battery from '../assets/images/features/Fast_Charging_Battery.jpg';
import Image_FeatureDrops from '../assets/images/features/Feature_Drops.jpg';
import Image_Tensor from '../assets/images/features/Google_Tensor.jpg';
import Image_CallFeatures from '../assets/images/features/Helpful_Call_Features.jpg';
import Image_MicCamToggle from '../assets/images/features/Mic_Cam_Toggle.jpg';
import Image_Safety from '../assets/images/features/Personal_Safety.jpg';
import Image_RealTone from '../assets/images/features/Real_Tone.jpg';
import Image_Speech from '../assets/images/features/Speech_Enhancement.jpg';
import Image_Ultrawide from '../assets/images/features/Ultrawide_Lens.jpg';
import Image_Selfies from '../assets/images/features/Ultrawide_Selfies.jpeg';
import Image_Voice from '../assets/images/features/Voice_Typing.jpg';
import Image_ProLevelLens from '../assets/images/features/Pro_Level_Lens.jpg';
import Image_ProLevelLensBlack from '../assets/images/features/Pro_Level_Lens-black.jpg';
import Image_ProLevelLens_en_us from '../assets/images/features/Pro_Level_Lens-en_us.jpg';
import Image_ProLevelLens_de_de from '../assets/images/features/Pro_Level_Lens-de_de.jpg';
import Image_ProLevelLens_en_au from '../assets/images/features/Pro_Level_Lens-en_au.jpg';
import Image_ProLevelLens_en_ca from '../assets/images/features/Pro_Level_Lens-en_ca.jpg';
import Image_ProLevelLens_en_gb from '../assets/images/features/Pro_Level_Lens-en_gb.jpg';
import Image_ProLevelLens_en_sg from '../assets/images/features/Pro_Level_Lens-en_sg.jpg';
import Image_ProLevelLens_fr_ca from '../assets/images/features/Pro_Level_Lens-fr_ca.jpg';
import Image_ProLevelLens_fr_fr from '../assets/images/features/Pro_Level_Lens-fr_fr.jpg';
import Image_ProLevelLens_ja_jp from '../assets/images/features/Pro_Level_Lens-ja_jp.jpg';
import Image_ProLevelLens_zh_tw from '../assets/images/features/Pro_Level_Lens-zh_tw.jpg';
import Image_Zoom from '../assets/images/features/Zoom.jpg';
import Image_MagicEraser from '../assets/images/features/Magic_Eraser.jpg';
import Image_MotionMode from '../assets/images/features/Motion_Mode.jpg';
import Image_NewPixelExperience from '../assets/images/features/New_Pixel_Experience.jpg';
import Image_NewPixelExperience_en_us from '../assets/images/features/New_Pixel_Experience-en_us.jpg';
import Image_LiveSpace from '../assets/images/features/Live_Space.jpg';
import Image_LiveSpace_en_us from '../assets/images/features/Live_Space-en_us.jpg';
import Image_LiveSpace_de_de from '../assets/images/features/Live_Space-de_de.jpg';
import Image_LiveSpace_en_ca from '../assets/images/features/Live_Space-en_ca.jpg';
import Image_LiveSpace_en_gb from '../assets/images/features/Live_Space-en_gb.jpg';
import Image_LiveSpace_en_sg from '../assets/images/features/Live_Space-en_sg.jpg';
import Image_LiveSpace_fr_ca from '../assets/images/features/Live_Space-fr_ca.jpg';
import Image_LiveSpace_fr_fr from '../assets/images/features/Live_Space-fr_fr.jpg';
import Image_LiveSpace_ja_jp from '../assets/images/features/Live_Space-ja_jp.jpg';
import Image_LiveSpace_zh_tw from '../assets/images/features/Live_Space-zh_tw.jpg';
import Image_LiveTranslate from '../assets/images/features/Live_Translate.jpg';

import Image_SecurityHubTile_en_us from '../assets/images/features/Security_Hub-en_us-tile.jpeg';
import Image_SecurityHubTile_de_de from '../assets/images/features/Security_Hub-de_de-tile.jpeg';
import Image_SecurityHubTile_en_au from '../assets/images/features/Security_Hub-en_au-tile.jpeg';
import Image_SecurityHubTile_en_ca from '../assets/images/features/Security_Hub-en_ca-tile.jpeg';
import Image_SecurityHubTile_en_sg from '../assets/images/features/Security_Hub-en_sg-tile.jpeg';
import Image_SecurityHubTile_fr_ca from '../assets/images/features/Security_Hub-fr_ca-tile.jpeg';
import Image_SecurityHubTile_fr_fr from '../assets/images/features/Security_Hub-fr_fr-tile.jpeg';
import Image_SecurityHubTile_ja_jp from '../assets/images/features/Security_Hub-ja_jp-tile.jpeg';
import Image_SecurityHubTile_zh_tw from '../assets/images/features/Security_Hub-zh_tw-tile.jpeg';
import Image_SecurityHubLarge_en_us from '../assets/images/features/Security_Hub-en_us-large.jpeg';
import Image_SecurityHubLarge_de_de from '../assets/images/features/Security_Hub-de_de-large.jpeg';
import Image_SecurityHubLarge_en_au from '../assets/images/features/Security_Hub-en_au-large.jpeg';
import Image_SecurityHubLarge_en_ca from '../assets/images/features/Security_Hub-en_ca-large.jpeg';
import Image_SecurityHubLarge_en_sg from '../assets/images/features/Security_Hub-en_sg-large.jpeg';
import Image_SecurityHubLarge_fr_ca from '../assets/images/features/Security_Hub-fr_ca-large.jpeg';
import Image_SecurityHubLarge_fr_fr from '../assets/images/features/Security_Hub-fr_fr-large.jpeg';
import Image_SecurityHubLarge_ja_jp from '../assets/images/features/Security_Hub-ja_jp-large.jpeg';
import Image_SecurityHubLarge_zh_tw from '../assets/images/features/Security_Hub-zh_tw-large.jpeg';

/*
Video
*/
import Video_MagicEraser from '../assets/video/features/MagicEraser.mp4';
import Video_MotionMode from '../assets/video/features/MotionMode.mp4';
import Video_NewPixelExperience from '../assets/video/features/New_Pixel_Experience.mp4';
import Video_NewPixelExperience_de_de from '../assets/video/features/New_Pixel_Experience-de_de.mp4';
import Video_NewPixelExperience_en_us from '../assets/video/features/New_Pixel_Experience-en_us.mp4';
import Video_NewPixelExperience_fr_ca from '../assets/video/features/New_Pixel_Experience-fr_ca.mp4';
import Video_NewPixelExperience_fr_fr from '../assets/video/features/New_Pixel_Experience-fr_fr.mp4';
import Video_NewPixelExperience_ja_jp from '../assets/video/features/New_Pixel_Experience-ja_jp.mp4';
import Video_NewPixelExperience_zh_tw from '../assets/video/features/New_Pixel_Experience-zh_tw.mp4';
import Video_LiveTranslate from '../assets/video/features/Live_Translate-en_us.mp4';
import Video_LiveTranslate_en_au from '../assets/video/features/Live_Translate-en_au.mp4';
import Video_LiveTranslate_en_ca from '../assets/video/features/Live_Translate-en_ca.mp4';
import Video_LiveTranslate_en_gb from '../assets/video/features/Live_Translate-en_gb.mp4';
import Video_LiveTranslate_en_sg from '../assets/video/features/Live_Translate-en_sg.mp4';
import Video_LiveTranslate_fr_ca from '../assets/video/features/Live_Translate-fr_ca.mp4';
import Video_LiveTranslate_fr_fr from '../assets/video/features/Live_Translate-fr_fr.mp4';
import Video_LiveTranslate_ja_jp from '../assets/video/features/Live_Translate-ja_jp.mp4';
import Video_LiveTranslate_de_de from '../assets/video/features/Live_Translate-de_de.mp4';
import Video_Zoom from '../assets/video/features/Zoom.mp4';

/*
Data
*/
const data = {
  _120hz: {
    tile: Image_120hz,
    slider: Image_120hz
  },
  _4k: {
    tile: Image_4k,
    slider: Image_4k
  },
  _5g: {
    tile: Image_5g,
    slider: Image_5g
  },
  _5YearsUpdates: {
    tile: Image_5YearsUpdates,
    slider: Image_5YearsUpdates
  },
  AntiVirus: {
    tile: Image_AntiVirus,
    slider: Image_AntiVirus
  },
  Sensors: {
    tile: Image_Sensors,
    slider: Image_Sensors
  },
  Battery: {
    tile: Image_Battery,
    slider: Image_Battery
  },
  FeatureDrops: {
    tile: Image_FeatureDrops,
    slider: Image_FeatureDrops
  },
  Tensor: {
    tile: Image_Tensor,
    slider: Image_Tensor
  },
  CallFeatures: {
    tile: Image_CallFeatures,
    slider: Image_CallFeatures
  },
  MicCamToggle: {
    tile: Image_MicCamToggle,
    slider: Image_MicCamToggle
  },
  Safety: {
    tile: Image_Safety,
    slider: Image_Safety
  },
  RealTone: {
    tile: Image_RealTone,
    slider: Image_RealTone
  },
  Security: {
    tile: {
      default: Image_SecurityHubTile_en_us,
      [langSettings.en_us.key]: Image_SecurityHubTile_en_us,
      [langSettings.de_de.key]: Image_SecurityHubTile_de_de,
      [langSettings.en_au.key]: Image_SecurityHubTile_en_au,
      [langSettings.en_ca.key]: Image_SecurityHubTile_en_ca,
      [langSettings.en_sg.key]: Image_SecurityHubTile_en_sg,
      [langSettings.fr_ca.key]: Image_SecurityHubTile_fr_ca,
      [langSettings.fr_fr.key]: Image_SecurityHubTile_fr_fr,
      [langSettings.ja_jp.key]: Image_SecurityHubTile_ja_jp,
      [langSettings.zh_tw.key]: Image_SecurityHubTile_zh_tw
    },
    slider: {
      default: Image_SecurityHubLarge_en_us,
      [langSettings.en_us.key]: Image_SecurityHubLarge_en_us,
      [langSettings.de_de.key]: Image_SecurityHubLarge_de_de,
      [langSettings.en_au.key]: Image_SecurityHubLarge_en_au,
      [langSettings.en_ca.key]: Image_SecurityHubLarge_en_ca,
      [langSettings.en_sg.key]: Image_SecurityHubLarge_en_sg,
      [langSettings.fr_ca.key]: Image_SecurityHubLarge_fr_ca,
      [langSettings.fr_fr.key]: Image_SecurityHubLarge_fr_fr,
      [langSettings.ja_jp.key]: Image_SecurityHubLarge_ja_jp,
      [langSettings.zh_tw.key]: Image_SecurityHubLarge_zh_tw
    }
  },
  Speech: {
    tile: Image_Speech,
    slider: Image_Speech
  },
  Ultrawide: {
    tile: Image_Ultrawide,
    slider: Image_Ultrawide
  },
  Selfies: {
    tile: Image_Selfies,
    slider: Image_Selfies
  },
  Voice: {
    tile: Image_Voice,
    slider: Image_Voice
  },
  ProLevelLens: {
    tile: {
      default: Image_ProLevelLens,
      [langSettings.fr_fr.key]: Image_ProLevelLensBlack,
      [langSettings.zh_tw.key]: Image_ProLevelLensBlack
    },
    slider: {
      default: Image_ProLevelLens,
      [langSettings.en_us.key]: Image_ProLevelLens_en_us,
      [langSettings.de_de.key]: Image_ProLevelLens_de_de,
      [langSettings.en_au.key]: Image_ProLevelLens_en_au,
      [langSettings.en_ca.key]: Image_ProLevelLens_en_ca,
      [langSettings.en_gb.key]: Image_ProLevelLens_en_gb,
      [langSettings.en_sg.key]: Image_ProLevelLens_en_sg,
      [langSettings.fr_ca.key]: Image_ProLevelLens_fr_ca,
      [langSettings.fr_fr.key]: Image_ProLevelLens_fr_fr,
      [langSettings.ja_jp.key]: Image_ProLevelLens_ja_jp,
      [langSettings.zh_tw.key]: Image_ProLevelLens_zh_tw
    }
  },
  Zoom: {
    tile: Image_Zoom,
    slider: Video_Zoom
  },
  MagicEraser: {
    tile: Image_MagicEraser,
    slider: Video_MagicEraser
  },
  MotionMode: {
    tile: Image_MotionMode,
    slider: Video_MotionMode
  },
  NewPixelExperience: {
    tile: {
      default: Image_NewPixelExperience,
      [langSettings.en_us.key]: Image_NewPixelExperience_en_us
    },
    slider: {
      default: Video_NewPixelExperience,
      [langSettings.de_de.key]: Video_NewPixelExperience_de_de,
      [langSettings.en_us.key]: Video_NewPixelExperience_en_us,
      [langSettings.fr_ca.key]: Video_NewPixelExperience_fr_ca,
      [langSettings.fr_fr.key]: Video_NewPixelExperience_fr_fr,
      [langSettings.ja_jp.key]: Video_NewPixelExperience_ja_jp,
      [langSettings.zh_tw.key]: Video_NewPixelExperience_zh_tw
    }
  },
  LiveSpace: {
    tile: Image_LiveSpace,
    slider: {
      default: Image_LiveSpace,
      [langSettings.en_us.key]: Image_LiveSpace_en_us,
      [langSettings.de_de.key]: Image_LiveSpace_de_de,
      [langSettings.en_ca.key]: Image_LiveSpace_en_ca,
      [langSettings.en_gb.key]: Image_LiveSpace_en_gb,
      [langSettings.en_sg.key]: Image_LiveSpace_en_sg,
      [langSettings.fr_ca.key]: Image_LiveSpace_fr_ca,
      [langSettings.fr_fr.key]: Image_LiveSpace_fr_fr,
      [langSettings.ja_jp.key]: Image_LiveSpace_ja_jp,
      [langSettings.zh_tw.key]: Image_LiveSpace_zh_tw
    }
  },
  LiveTranslate: {
    tile: Image_LiveTranslate,
    slider: {
      default: Video_LiveTranslate,
      [langSettings.en_au.key]: Video_LiveTranslate_en_au,
      [langSettings.en_ca.key]: Video_LiveTranslate_en_ca,
      [langSettings.en_gb.key]: Video_LiveTranslate_en_gb,
      [langSettings.en_sg.key]: Video_LiveTranslate_en_sg,
      [langSettings.fr_ca.key]: Video_LiveTranslate_fr_ca,
      [langSettings.fr_fr.key]: Video_LiveTranslate_fr_fr,
      [langSettings.ja_jp.key]: Video_LiveTranslate_ja_jp,
      [langSettings.zh_tw.key]: Video_LiveTranslate_ja_jp,
      [langSettings.de_de.key]: Video_LiveTranslate_de_de
    }
  }
};

export default data;
