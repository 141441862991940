const trackingKeys = {
  prefix: {
    pageview: 'p-demo-6'
  },
  type: {
    pageview: 'pageview',
    event: 'event'
  },
  category: {
    module: 'module',
    config: 'config',
    misc: 'misc',
    global: 'global',
    welcome: 'welcome',
    interests: 'interests',
    results: 'results',
    accessories: 'accessories',
    features: 'features',
    specs: 'specs',
    switching: 'switching'
  },
  actions: {
    view: 'view',
    click: 'click',
    swipe: 'swipe',
    log: 'log'
  }
};

export default trackingKeys;
