import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import 'normalize.css';

import '../styles/global.scss';

import { IS_PROD } from '../settings/settings.params';
import useWindowResizeListener from '../utils/hooks/use-window-resize-listener';
import DesktopScreen from '../components/DesktopScreen/DesktopScreen';
import Layout from '../components/Layout/Layout';

import detect, { isTouchDevice } from '../utils/detect';
//import '../utils/why-did-you-render';
import useNavStore from '../store/nav';
import useWindowSizeStore from '../store/window-size';
import useAppStore from '../store/app';
import Header from '@/components/Header/Header';
import { TRANSITION_TIME } from '@/components/PageTransition/PageTransition';

import { Flip } from '@/vendor/gsap-club/src/Flip';

// lazy loaded
const MenuOverlay = dynamic(() => import('@/components/MenuOverlay/MenuOverlay'));

const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  require('default-passive-events');
  require('focus-visible');
}

if (isBrowser && !IS_PROD) {
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

// This default export is required in a new `pages/_app.js` file.
function App({ Component, pageProps }) {
  const { isUnsupported, ...componentProps } = pageProps;
  const setIsNavOpen = useNavStore((state) => state.setIsNavOpen);
  const isBreakpointDesktop = useWindowSizeStore((state) => state.isBreakpointDesktop);
  const isWindowSizeSet = useWindowSizeStore((state) => state.isWindowSizeSet);
  const [hasReRouted, setHasReRouted] = useState(false);
  const { setInitialHeight } = useAppStore();

  useWindowResizeListener();

  const router = useRouter();

  useEffect(() => {
    if (isBrowser) {
      if (!IS_PROD && window.location.href.indexOf('?nostat') === -1) {
        require('@jam3/stats')();
      }

      const { device, browser } = detect;
      const classes = [isTouchDevice ? 'touch-device' : '', device.getType(), browser.getName()].filter((className) =>
        Boolean(className)
      );
      document.body.className = [...document.body.className.split(' '), ...classes].filter(Boolean).join(' ');

      // register GSAP plugins
      gsap.registerPlugin(Flip);

      setInitialHeight(window.innerHeight);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const delay = IS_PROD ? TRANSITION_TIME + 50 : 0;

    if (IS_PROD) {
      router.push('/');
    }

    setTimeout(() => {
      setHasReRouted(true);
    }, delay);
    // eslint-disable-next-line
  }, []);

  const onToggleMenu = (e) => {
    e.preventDefault();
    setIsNavOpen(true);
  };

  const renderContent = () => {
    if (isWindowSizeSet && hasReRouted) {
      return (
        <>
          {!isBreakpointDesktop && (
            <>
              <Header onClick={onToggleMenu} />
              <MenuOverlay />
            </>
          )}
          <Layout>{isBreakpointDesktop ? <DesktopScreen /> : <Component {...componentProps} />}</Layout>
        </>
      );
    } else {
      return null;
    }
  };

  return isUnsupported ? <Component {...componentProps} /> : renderContent();
}

export default App;
