import routes from '../settings/settings.routes';

// Add small functions you use as utils here

export function cleanUrl(path = '', cleanParams) {
  if (!path) return '';
  if (cleanParams) path = path?.split('?')[0];
  if (path === '/') return '/';
  return path.replace(/\/$/, '').replace(/^\//, '') || '';
}

/**
 * @export
 * @function Clamp.
 * @description Keeps a number between two values.
 * @param {Number} number - Number to clamp.
 * @param {Number} minimum - The smallest possible value.
 * @param {Number} maximum - The largest possible value.
 * @return {Number} The clamped array
 */
export const clamp = (number, minimum, maximum) => Math.min(Math.max(number, minimum), maximum);

/**
 * @export
 * @function NumberInRange.
 * @description Function to determine if a number is between two number. It can be inclusive or
 * exclusive.
 * @param {Object} obj - An object.
 * @param {Number} obj.min -  The minimum value.
 * @param {Number} obj.max -  The maximum value.
 * @param {Number} obj.number - The value/number to check if it is within the range.
 * @param {Boolean} obj.inclusive - If true, the range will include the min and max values.
 * Otherwise, min and max won't be included in the range.
 * @return {Boolean} True if within the range.
 */
export const numberInRange = ({ min, max, number, inclusive }) =>
  inclusive ? (number - min) * (number - max) <= 0 : (number - min) * (number - max) < 0;

// https://github.com/processing/p5.js/blob/f81bb70089eb0d9bef4d51515fb80a72daa04a44/src/math/calculation.js#L450
export function map(n, start1, stop1, start2, stop2) {
  // eslint-disable-next-line no-mixed-operators
  return ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
}

/**
 * @export
 * @function wait
 * @description Wait a specific amount of time and resolve a promise.
 * @param {Number} [ms=0] - The number of milliseconds to wait before resolving the promise.
 * @return {Promise} Promise object that will resolve after n milliseconds
 */
export const wait = (ms = 0) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * @export
 * @function simpleImagesPreload
 * @description Preload images and then execute a callback
 * @param {Array} [urls=[]] - An array containing URL strings of images to preload
 * @param {Function} [allImagesLoadedCallback] - A callback function once loaded
 */
export const simpleImagesPreload = (urls, allImagesLoadedCallback) => {
  let loadedCounter = 0;
  const toBeLoadedNumber = urls.length;

  urls.forEach(function (url) {
    preloadImage(url, function () {
      loadedCounter++;
      if (loadedCounter === toBeLoadedNumber) {
        allImagesLoadedCallback();
      }
    });
  });

  function preloadImage(url, anImageLoadedCallback) {
    const img = new Image();
    img.onload = anImageLoadedCallback;
    img.src = url;
  }
};

/**
 * @export
 * @function getRouteTrackingKeyByUrl
 */

export const getRouteTrackingKeyByUrl = (url) => {
  let routeObject = Object.values(routes).filter((routeObj) => {
    let path = routeObj.path;

    //If is home
    if (path === '/') {
      return path === url;
    }

    //Check other URLs
    if (path.slice(-1) === '/') {
      path = path.slice(0, -1);
    }

    return path === url;
  })[0];

  if (!routeObject) routeObject = routes._404;
  const { trackingKey } = routeObject;

  return trackingKey;
};
