import React from 'react';
import {
  TransitionGroup,
  Transition as ReactTransition
} from 'react-transition-group';
import styles from './PageTransition.module.scss';

import { useTransitionFix } from '../../utils/hooks/use-transition-fix';

export const TRANSITION_TIME = 400;

const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0
  },
  entered: {
    transition: `opacity ${TRANSITION_TIME}ms ease-in-out`,
    opacity: 1
  },
  exiting: {
    transition: `opacity ${TRANSITION_TIME}ms ease-in-out`,
    opacity: 0
  },
  exited: {
    opacity: 0
  }
};

const PageTransition = ({ children, location, isActive }) => {
  const onExited = useTransitionFix();

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, TRANSITION_TIME);
  };

  return (
    <TransitionGroup className={styles.Transition}>
      <ReactTransition
        key={location}
        timeout={{
          enter: TRANSITION_TIME,
          exit: TRANSITION_TIME
        }}
      >
        {(status) => {
          if (status === 'exited') {
            onExited();
            scrollToTop();
          }

          return (
            <div
              style={{
                ...getTransitionStyles[status]
              }}
              className={isActive ? '' : styles.hide}
            >
              {children}
            </div>
          );
        }}
      </ReactTransition>
    </TransitionGroup>
  );
};
export default PageTransition;
