import React, { memo, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import { gsap } from 'gsap';

import circleButtonKeys from '../../keys/circleButton';

import styles from './Header.module.scss';
import CircleButton from '../CircleButton/CircleButton';
import BurgerSvg from '../../assets/svgs/burger.svg';
import GoogleLogoPng from '../../assets/images/header-google-logo.png';

import store from '../../store';
import useCopy from '@/utils/hooks/use-copy';
import ImagePreloader from '@/components/ImagePreloader/ImagePreloader';
import useTracking from '@/utils/hooks/use-tracking';
import routes from '@/settings/settings.routes';

export const CLASSNAME_HEADER_SPACER = styles.headerSpacer;

function Header({ className, onClick }) {
  const { trackEvent } = useTracking();
  const router = useRouter();
  const headerRef = useRef();
  const headerIsVisible = store.nav((state) => state.headerIsVisible);
  const setHeaderIsVisible = store.nav((state) => state.setHeaderIsVisible);
  const prevRoute = store.app((state) => state.prevRoute);

  const copy = useCopy();

  const animate = (isShowing) => {
    if (headerRef.current) {
      gsap.to(headerRef.current, {
        opacity: isShowing ? 1 : 0,
        pointerEvents: isShowing ? 'all' : 'none',
        duration: 0.4
      });
    }
  };

  useEffect(() => {
    const hideHeaderPages = [routes.Landing.path];
    const showHeader = !hideHeaderPages.includes(router.asPath);
    const isResultsFlow =
      prevRoute === routes.Landing.path &&
      router.asPath === routes.Results.path;
    const isResultsOnLoad =
      prevRoute === '' && router.asPath === routes.Results.path;

    if (showHeader) {
      if (!isResultsFlow && !isResultsOnLoad) {
        setHeaderIsVisible(true);
      }
    } else {
      setHeaderIsVisible(false);
    }
  }, [router.asPath, setHeaderIsVisible, prevRoute]);

  useEffect(() => {
    animate(headerIsVisible);
  }, [headerIsVisible]);

  const handleLogoClick = () => {
    trackEvent('GLOBAL_LOGO_CLICK');
    router.push(routes.Landing.path);
  };

  return (
    <>
      <div className={CLASSNAME_HEADER_SPACER} />
      <div className={classnames(styles.Header, className)} ref={headerRef}>
        <button
          onClick={handleLogoClick}
          className={styles.logoContainer}
          aria-label={copy('aria_header_button', true)}
        >
          <ImagePreloader
            className={styles.logoImage}
            alt="The Google Logo"
            src={GoogleLogoPng}
            width="20px"
            height="20px"
          />
          <span>{copy('results_page_QR_tag')}</span>
        </button>

        <CircleButton
          ariaLabel={copy('aria_open_menu', true)}
          theme={circleButtonKeys.LIGHT_GREY}
          onClick={(e) => {
            e.preventDefault();

            if (onClick) {
              onClick(e);
            }
            trackEvent('GLOBAL_MENU_BUTTON_CLICK', {
              OPENED_OR_CLOSED: 'opened'
            });
          }}
        >
          <div className={styles.hamburgerButton}>
            <BurgerSvg />
          </div>
        </CircleButton>
      </div>
    </>
  );
}

Header.propTypes = checkProps({
  className: PropTypes.string,
  onClick: PropTypes.func
});

Header.defaultProps = {};

export default memo(Header);
