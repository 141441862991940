import features from '@/data/features.js';

const category = {
  name: 'photography',
  key: 'swipe_interest_QR_photography_card_headline',
  body: 'swipe_interest_QR_photography_card_body',
  questionKey: 'swipe_interest_QR_Photo_Question',
  groups: [
    {
      groupName: '',
      groupKey: 'swipe_interest_QR_Photo_button_1',
      featureList: [
        features['pro-level-lens'],
        features['4k-video'],
        features['motion-mode'],
        features['zoom'],
        features['bigger-sensors'],
        features['ultrawide-selfies']
      ]
    }
  ]
};

export { category as default };
