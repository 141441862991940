import create from 'zustand';

import detect, { isPixel } from '../utils/detect';
import interests from '../settings/interests/';
import { FEATURE_LIST_SIZE } from '../settings/settings.ui';
import features from '@/data/features';

import getFilteredFeatures, { featureIsExcluded } from '@/utils/get-filtered-features';

function getWeightedFeatureList(locale = 'en_us', selection = []) {
  if (!selection.length) {
    selection = [{ categoryName: 'OS', groupIndex: 0 }];

    if (isPixel) {
      selection[0].groupIndex = 1;
    } else if (detect.os.isAndroid) {
      selection[0].groupIndex = 2;
    }
  }

  /**
   *   Going through the loop in row-column fashion.
   *
   * - i is for iterating through the `selection` array.
   * - j is for iterating through the `featureList` array.
   *   Hence, it's updated every time `selectionIndex` is
   *   at the end of the `selection` array.
   */
  let forceStop = false;
  let results = [];
  let i = 0;
  let j = 0;
  while (results.length < FEATURE_LIST_SIZE || forceStop) {
    const selectionIndex = i % selection.length;
    const sel = selection[selectionIndex];
    const cat = interests[sel.categoryName];
    const group = cat.groups[0];
    const feature = group.featureList[j];

    if (!results.includes(feature)) {
      results.push(feature);
    }

    if (selectionIndex === selection.length - 1) {
      j++;
    }
    i++;

    /**
     * Hard force stop in case the `results` array is still
     * less than FEATURE_LIST_SIZE (6) at the end of all loops.
     */
    if (i === selection.length && j === FEATURE_LIST_SIZE - 1) {
      forceStop = true;
    }
  }

  results = getFilteredFeatures(locale, [...results]);

  /**
   * In most cases, this function should
   * stop here and return. <============
   */

  /**
   * But, if the results array still has less than 6 features,
   * we should iterate through each feature and
   * add it into the array randomly.
   */
  if (results.length !== FEATURE_LIST_SIZE) {
    const allFeatureKeys = Object.keys(features);
    let k = 0;
    while (results.length < FEATURE_LIST_SIZE && k < allFeatureKeys.length) {
      const feature = features[allFeatureKeys[k]];
      if (!results.includes(feature) && !featureIsExcluded(locale, feature.id)) {
        results.push(feature);
      }
      k++;
    }
  }

  return results;
}

const useFeatureStore = create((set, get) => {
  return {
    features: [],
    setFeature: (feature) => {
      set((state) => ({
        features: [...state.features, feature]
      }));
    },
    resetFeatures: () => {
      set((state) => ({ features: [] }));
    },
    weightedFeatures: [],
    computeWeightedFeatureList: (locale) => {
      set((state) => ({
        weightedFeatures: getWeightedFeatureList(locale, state.features)
      }));
    }
  };
});

export default useFeatureStore;
