import create from 'zustand';

const useNavStore = create((set) => {
  return {
    isNavOpen: false,
    setIsNavOpen: (val) => set(() => ({ isNavOpen: val })),
    headerIsVisible: false,
    setHeaderIsVisible: (val) => {
      set(() => ({ headerIsVisible: val }));
    }
  };
});

export default useNavStore;
