import keys from '../keys/tracking';

const { category, actions } = keys;

const events = {
  /* ================================
  //
  // Global Element Events
  //
  ================================ */
  GLOBAL_LOGO_CLICK: {
    id: 'e-demo-global-logo-click',
    event: 'Global - Header Logo Click',
    action: actions.click,
    category: category.global,
    label: '[LOCALE] - Global - Logo - Click'
  },
  GLOBAL_MENU_BUTTON_CLICK: {
    id: 'e-demo-global-menu-button-click',
    event: 'Global - Header Menu Button Click',
    action: actions.click,
    category: category.global,
    label: '[LOCALE] - Global - Menu Button - Click - [OPENED_OR_CLOSED]'
  },
  GLOBAL_NAVIGATION_ITEM_CLICK: {
    id: 'e-demo-global-navigation-click',
    event: 'Global - Navigation Item Click',
    action: actions.click,
    category: category.global,
    label: '[LOCALE] - Global - Navigation Item - Click - [URL]'
  },
  GLOBAL_LANGUAGE_BUTTON_CLICK: {
    id: 'e-demo-global-select-language-button-click',
    event: 'Global - Select Language Button Click',
    action: actions.click,
    category: category.global,
    label: '[LOCALE] - Global - Select Language Button - Click'
  },
  GLOBAL_SELECT_LANGUAGE_CLICK: {
    id: 'e-demo-global-select-language-click',
    event: 'Global - Select Language Click',
    action: actions.click,
    category: category.global,
    label: '[LOCALE] - Global - Select Language Button - Click - [LOCALE_SELECTED]'
  },
  /* ================================
  //
  // Misc components
  //
  ================================ */
  MISC_DESKTOP_VIEW: {
    id: 'e-demo-page-desktop-view',
    event: 'View on Desktop',
    action: actions.view,
    category: category.misc,
    label: '[LOCALE] - Desktop - View'
  },
  MISC_LANDSCAPE_VIEW: {
    id: 'e-demo-page-landscape-view',
    event: 'View in Landscape Orientation',
    action: actions.view,
    category: category.misc,
    label: '[LOCALE] - Landscape Orientation - View'
  },
  /* ================================
  //
  // Home page / Welcome Events
  //
  ================================ */
  WELCOME_SWIPE_SELECT: {
    id: 'e-demo-welcome-feature-swipe-select',
    event: 'Welcome - Swipe Interest Select',
    category: category.welcome,
    action: actions.swipe,
    label: '[LOCALE] - Welcome - Feature - Select - [FEATURE]'
  },
  WELCOME_SWIPE_UNINTERESTED: {
    id: 'e-demo-welcome-feature-swipe-uninterested',
    event: 'Welcome - Swipe Uninterested',
    category: category.welcome,
    action: actions.swipe,
    label: '[LOCALE] - Welcome - Feature - Uninterested - [FEATURE]'
  },
  WELCOME_CLICK_SELECT: {
    id: 'e-demo-welcome-feature-click-select',
    event: 'Welcome - Click Interest Select',
    category: category.welcome,
    action: actions.click,
    label: '[LOCALE] - Welcome - Feature - Select - [FEATURE]'
  },
  WELCOME_CLICK_UNINTERESTED: {
    id: 'e-demo-welcome-feature-click-uninterested',
    event: 'Welcome - Click Uninterested',
    category: category.welcome,
    action: actions.click,
    label: '[LOCALE] - Welcome - Feature - Uninterested - [FEATURE]'
  },
  WELCOME_CLICK_MEET_MATCH: {
    id: 'e-demo-welcome-meet-match',
    event: 'Welcome - Clicked Meet Match',
    category: category.welcome,
    action: actions.click,
    label: '[LOCALE] - Welcome - Meet Match'
  },
  WELCOME_COMPLETE: {
    id: 'e-demo-welcome-complete',
    event: 'Welcome - Finished Selection',
    category: category.welcome,
    action: actions.view,
    label: '[LOCALE] - Welcome -  Finished Selection'
  },
  /* ================================
  //
  // Results page
  //
  ================================ */
  RESULTS_FEATURES_LINK_CLICK: {
    id: 'e-demo-results-features-link-click',
    event: 'Results - Features Link Click',
    category: category.results,
    action: actions.click,
    label: '[LOCALE] - Results - All Features - Click'
  },
  /* ================================
  //
  // Modules
  //
  ================================ */
  MODULE_LEGAL_BACK_TO_TOP_CLICK: {
    id: '[PAGE]-e-demo-legal-back-to-top-click',
    event: '[PAGE] - Module - Legal Back-to-Top Click',
    category: category.module,
    action: actions.click,
    label: '[PAGE] - [LOCALE] - Module - Legal Back-to-Top - Click'
  },
  MODULE_CROSSLINK_CLICK: {
    id: '[PAGE]-e-demo-crosslink-click',
    event: '[PAGE] - Module - Crosslink Click',
    category: category.module,
    action: actions.click,
    label: '[PAGE] - [LOCALE] - Module - Crosslink - Click - [URL]'
  },
  MODULE_TILE_CLICK: {
    id: '[PAGE]-e-demo-square-list-item-click',
    event: '[PAGE] - Module - Square List Item Click',
    category: category.module,
    action: actions.click,
    label: '[PAGE] - [LOCALE] - Module - List Item - Click - [FEATURE]'
  },
  MODULE_CAROUSEL_CLOSE_CLICK: {
    id: '[PAGE]-e-demo-carousel-close-click',
    event: '[PAGE] - Module - Carousel Close Click',
    category: category.module,
    action: actions.click,
    label: '[PAGE] - [LOCALE] - Module - Close Button - Click'
  },
  MODULE_CAROUSEL_NEXT_CLICK: {
    id: '[PAGE]-e-demo-carousel-next-click',
    event: '[PAGE] - Module - Carousel Next Click',
    category: category.module,
    action: actions.click,
    label: '[PAGE] - [LOCALE] - Module - Carousel Next Button - Click'
  },
  MODULE_CAROUSEL_PREV_CLICK: {
    id: '[PAGE]-e-demo-carousel-previous-click',
    event: '[PAGE] - Module - Carousel Previous Click',
    category: category.module,
    action: actions.click,
    label: '[PAGE] - [LOCALE] - Module - Carousel Previous Button - Click'
  },
  MODULE_CAROUSEL_NEXT_SWIPE: {
    id: '[PAGE]-e-demo-carousel-next-swipe',
    event: '[PAGE] - Module - Carousel Next Swipe',
    category: category.module,
    action: actions.swipe,
    label: '[PAGE] - [LOCALE] - Module - Carousel Next Button - Swipe'
  },
  MODULE_CAROUSEL_PREVIOUS_SWIPE: {
    id: '[PAGE]-e-demo-carousel-previous-swipe',
    event: '[PAGE] - Module - Carousel Previous Swipe',
    category: category.module,
    action: actions.swipe,
    label: '[PAGE] - [LOCALE] - Module - Carousel Previous Button - Swipe'
  },
  MODULE_ACCORDION_TOGGLE: {
    id: '[PAGE]-e-demo-accordion-toggle',
    event: '[PAGE] - Module - Accordion Toggle',
    category: category.module,
    action: actions.click,
    label: '[PAGE] - [LOCALE] - Module - Accordion Toggle - Click - [ACCORDION_TITLE] - [OPENED_OR_CLOSED]'
  },
  MODULE_SLIDER_NEXT_CLICK: {
    id: '[PAGE]-e-demo-slider-next-click',
    event: '[PAGE] - Module - Slider Next Click',
    category: category.module,
    action: actions.click,
    label: '[PAGE] - [LOCALE] - Module - Slider Next Button - Click'
  },
  MODULE_SLIDER_PREV_CLICK: {
    id: '[PAGE]-e-demo-slider-previous-click',
    event: '[PAGE] - Module - Slider Previous Click',
    category: category.module,
    action: actions.click,
    label: '[PAGE] - [LOCALE] - Module - Slider Previous Button - Click'
  },
  MODULE_SLIDER_NEXT_SWIPE: {
    id: '[PAGE]-e-demo-slider-next-swipe',
    event: '[PAGE] - Module - Slider Next Swipe',
    category: category.module,
    action: actions.swipe,
    label: '[PAGE] - [LOCALE] - Module - Slider Next Button - Swipe'
  },
  MODULE_SLIDER_PREVIOUS_SWIPE: {
    id: '[PAGE]-e-demo-slider-previous-swipe',
    event: '[PAGE] - Module - Slider Previous Swipe',
    category: category.module,
    action: actions.swipe,
    label: '[PAGE] - [LOCALE] - Module - Slider Previous Button - Swipe'
  }
};

export default events;
