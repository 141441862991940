import create from 'zustand';
import {
  isBreakpointPhone,
  isBreakpointTablet,
  isBreakpointDesktop
} from '../utils/breakpoint-utils';

const useWindowSizeStore = create((set) => {
  return {
    windowSize: {
      width: 0,
      height: 0
    },
    isWindowSizeSet: false,
    isBreakpointDesktop: false,
    isBreakpointMobile: false,
    isBreakpointTablet: false,
    isBreakpointPhone: false,

    setWindowSize: (val) => {
      const isBreakpointPhoneVal = isBreakpointPhone(val.width);
      const isBreakpointTabletVal = isBreakpointTablet(val.width);
      const isBreakpointMobileVal =
        isBreakpointPhoneVal || isBreakpointTabletVal;
      const isBreakpointDesktopVal = isBreakpointDesktop(val.width);
      set(() => ({
        width: val.width,
        height: val.height,
        isBreakpointDesktop: isBreakpointDesktopVal,
        isBreakpointMobile: isBreakpointMobileVal,
        isBreakpointTablet: isBreakpointTabletVal,
        isBreakpointPhone: isBreakpointPhoneVal,
        isWindowSizeSet: true
      }));
    },
    setIsBreakpointDesktop: (val) => set(() => ({ isBreakpointDesktop: val })),
    setIsBreakpointMobile: (val) => set(() => ({ isBreakpointMobile: val })),
    setIsBreakpointTablet: (val) => set(() => ({ isBreakpointTablet: val })),
    setIsBreakpointPhone: (val) => set(() => ({ isBreakpointPhone: val }))
  };
});

export default useWindowSizeStore;
