import { FEATURE_LOCALE_EXCLUSION_MAP } from '@/data/features';

export const featureIsExcluded = (locale, featureId) => {
  const excluded = FEATURE_LOCALE_EXCLUSION_MAP[featureId]
    ? FEATURE_LOCALE_EXCLUSION_MAP[featureId].includes(locale)
    : false;
  return excluded;
};

const getFilteredFeatures = (locale, features) => {
  const result = features.filter((feature) => {
    return !featureIsExcluded(locale, feature.id);
  });

  return result;
};

export default getFilteredFeatures;
