import { useCallback } from 'react';
//import widont from 'widont'; //No word should be on its own line at end of paragraph
import store from '../../store';
import useCJKStyles from './use-cjk-styles';

export default function useCopy() {
  const textData = store.locale((state) => state.textData);
  const { CopyWrapper } = useCJKStyles();

  const copy = useCallback(
    (key, returnOriginalString, replacementObj) => {
      if (!textData) return '';
      if (!textData[key]) return `__No translation for ${key}__`;

      let languageString = textData[key];

      if (replacementObj) {
        Object.keys(replacementObj).forEach((replacementKey) => {
          const replaceWith = replacementObj[replacementKey];
          const toReplace = `$[${replacementKey}]`;
          languageString = languageString.replaceAll(toReplace, replaceWith);
        });
      }

      if (returnOriginalString) return languageString;

      return <CopyWrapper key={key} text={languageString} />;
    },
    [textData]
  );

  return copy;
}
