import { useCallback, useRef } from 'react';
import preferredLocale from 'preferred-locale';

import store from '../../store';

import pixelLocalStorage, { LOCAL_STORAGE_KEY } from '../../utils/localstorage';

import languageSettings from '../../settings/settings.languages';

const DEFAULT_LANG = languageSettings.en_us.key;

const useSetLang = () => {
  const localStorageData = useRef(pixelLocalStorage.get(LOCAL_STORAGE_KEY.PIXEL));
  const { setLocaleKey } = store.locale((state) => state);

  const setLocale = useCallback(
    (languageKey) => {
      localStorageData.current.lang = languageKey;
      pixelLocalStorage.set(LOCAL_STORAGE_KEY.PIXEL, localStorageData.current);
      setLocaleKey(languageKey);
    },
    [setLocaleKey]
  );

  const detectAndSetPreferredLocale = useCallback(() => {
    //If they have local storage, use that instead of fetching more
    if (localStorageData.current.lang) {
      return setLocale(localStorageData.current.lang);
    }

    //Need to format lang file keys to be like the following
    //[ 'en-US', 'en-GB', 'fr-FR' ]
    //In order to work with preferred-locale npm package
    //https://www.npmjs.com/package/preferred-locale
    const formatLocaleKey = (key) => {
      const splitStr = key.split('_');
      const formattedStr = `${splitStr[0]}-${splitStr[1].toUpperCase()}`;
      return formattedStr;
    };

    //Get array of formatted strings that work with preferred-locale package
    const formatted = Object.keys(languageSettings).map((langKey) => {
      return formatLocaleKey(langKey);
    });

    //Fallback key
    const fallback = formatLocaleKey(DEFAULT_LANG);

    //Get preferred locale string from package
    const preferredLocaleString = preferredLocale(formatted, fallback);

    //Re-format back to settings format ('en-US' => 'en_us')
    //To match formatting in settings.languages.js
    const reFormattedPreferred = preferredLocaleString.replace('-', '_').toLowerCase();

    //determine the preferred locale
    let finalKey = reFormattedPreferred;
    if (!languageSettings[reFormattedPreferred]) {
      finalKey = DEFAULT_LANG;
    }

    //Set the locale
    setLocale(finalKey);
  }, [setLocale]);

  return {
    setLocale,
    detectAndSetPreferredLocale
  };
};

export default useSetLang;
