import React, { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import Lottie from 'react-lottie';

import { gsap } from 'gsap';
import ImagePreloader from '../ImagePreloader/ImagePreloader';

import styles from './DesktopScreen.module.scss';

import Head from '../../components/Head/Head';
import DesktopLogoSvg from '../../assets/images/desktop-logo.png';
import qrCodePng from '../../assets/images/qr-code.png';
import desktopPhonePng from '../../assets/images/desktop-phone.jpg';
import qrAnimationData from '../../data/lottie/qrBorder.json';
import useCopy from '@/utils/hooks/use-copy';
import useTracking from '@/utils/hooks/use-tracking';
import { SplitText } from '@/vendor/gsap-club/src/SplitText';

const lottieOptions = {
  animationData: qrAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function DesktopScreen({ className }) {
  const { trackEvent } = useTracking();
  const tl = useRef();
  const heading = useRef(null);
  const body = useRef(null);
  const container = useRef(null);

  const qrCode = useRef(null);
  const hero = useRef(null);
  const copy = useCopy();

  useEffect(() => {
    // Delay timeline creation because split text has issues sometimes, if the font is not loaded yet
    setTimeout(() => {
      // Create line mask containers,
      // make sure this container has visibility: hidden
      // and the height of this container is enough for letters ike g
      const splitHeadingLineMasks = new SplitText(heading.current, {
        type: 'lines',
        linesClass: styles.splitLineMask
      });
      const splitHeadingLines = new SplitText(splitHeadingLineMasks.lines, {
        type: 'lines',
        linesClass: styles.headingLine
      });
      tl.current = gsap.timeline({ delay: 0 });
      tl.current
        .set(container.current, { visibility: 'visible' })
        .from(splitHeadingLines.lines, { duration: 1, stagger: 0.2, yPercent: 100, ease: 'power2.out' }, 'mask-heading')
        .from(body.current, { duration: 1, yPercent: 100, ease: 'power2.out' }, 'mask-heading+=0.25')
        .from(qrCode.current, { duration: 1, autoAlpha: 0, yPercent: 30, ease: 'power2.out' }, 'mask-heading+=0.55')
        .from(hero.current, { duration: 1, autoAlpha: 0, yPercent: 50, ease: 'power2.out' }, 'mask-heading+=0.8');
    }, 500);

    //Track desktop view
    trackEvent('MISC_DESKTOP_VIEW');

    // eslint-disable-next-line
  }, []);

  return (
    <div ref={container} className={classnames(styles.DesktopScreen, className)}>
      <Head title="qr_desktop_headline" />
      <div className={styles.logo}>
        <ImagePreloader src={DesktopLogoSvg} height={'100%'} fit={'contain'} />
      </div>
      <div className={styles.content}>
        <div className={styles.mask}>
          <h3 ref={heading} className={styles.heading}>
            {copy('qr_desktop_headline')}
          </h3>
        </div>
        <div className={classnames(styles.mask, styles.instructions)}>
          <p ref={body} className={styles.copy}>
            {copy('qr_desktop_body')}
          </p>
        </div>
        <div ref={qrCode} className={styles.qrContainer}>
          <ImagePreloader
            className={classnames(styles.qrCode, styles.image)}
            src={qrCodePng}
            height={'100%'}
            fit={'cover'}
          />
          <Lottie options={lottieOptions} />
        </div>
      </div>

      <div ref={hero} className={styles.desktopBkg}>
        <ImagePreloader withWorker={false} src={desktopPhonePng} width="100%" height="auto" />
      </div>
    </div>
  );
}

DesktopScreen.propTypes = checkProps({
  className: PropTypes.string
});

DesktopScreen.defaultProps = {};

export default memo(DesktopScreen);
