export const digitFromSuperscript = (str) => {
  const superMap = {
    '⁰': '0',
    '¹': '1',
    '²': '2',
    '³': '3',
    '⁴': '4',
    '⁵': '5',
    '⁶': '6',
    '⁷': '7',
    '⁸': '8',
    '⁹': '9'
  };

  return str.replace(/[⁰¹²³⁴⁵⁶⁷⁸⁹]/g, (match) => {
    return superMap[match];
  });
};

const normalizeCopyWithSuperscript = (copy) => {
  const superscripts = /[\u2070-\u209F\u00B2\u00B3\u00B9]+/;
  const superscriptMatch = copy ? copy.match(superscripts) : null;
  const superscriptNumber = superscriptMatch ? superscriptMatch[0] : null;
  const splitCopy = superscriptMatch ? copy.split(superscriptNumber) : null;

  const finalCopy = splitCopy ? splitCopy[1].trim() : copy ? copy.trim() : null;

  return {
    number: superscriptNumber ? digitFromSuperscript(superscriptNumber) : null,
    finalCopy
  };
};

export default normalizeCopyWithSuperscript;
