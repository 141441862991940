export default {
  //Themes (prop = theme={})
  WHITE: 'WHITE',
  LIGHT_GREY: 'LIGHT_GREY',
  SHADOW: 'SHADOW',
  DARK: 'DARK',
  GREEN: 'GREEN',
  RED: 'RED',
  LIGHT_GREY_TRANSPARENT: 'LIGHT_GREY_TRANSPARENT',
  WHITE_TRANSPARENT: 'WHITE_TRANSPARENT',
  //Size  (prop = size={}) (by default is 40x40)
  SMALL: 'SMALL', // Makes it 28x28
  LARGE: 'LARGE', // Makes it 28x28
  // Type: Either 'PLAIN' or 'BUTTON'
  PLAIN: 'PLAIN',
  BUTTON: 'BUTTON'
};
