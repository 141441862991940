import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import styles from './CircleButton.module.scss';

import circleButtonKeys from '../../keys/circleButton';

const THEME_SYLES = {
  [circleButtonKeys.LIGHT_GREY]: styles.lightGrey,
  [circleButtonKeys.LIGHT_GREY_TRANSPARENT]: styles.lightGreyTransparent,
  [circleButtonKeys.WHITE_TRANSPARENT]: styles.whiteTransparent,
  [circleButtonKeys.WHITE]: styles.white,
  [circleButtonKeys.SHADOW]: styles.shadow,
  [circleButtonKeys.GREEN]: styles.green,
  [circleButtonKeys.RED]: styles.red,
  [circleButtonKeys.DARK]: styles.dark
};

function CircleButton({
  className,
  theme,
  size,
  style,
  children,
  onClick,
  type,
  hideFromTab = false,
  ariaLabel = ''
}) {
  const isButton = type === circleButtonKeys.BUTTON;

  return isButton ? (
    <button
      className={classnames(
        styles.CircleButton,
        className,
        THEME_SYLES[theme],
        {
          [styles.small]: size === circleButtonKeys.SMALL,
          [styles.large]: size === circleButtonKeys.LARGE
        }
      )}
      style={style}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      }}
      tabIndex={hideFromTab ? -1 : null}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  ) : (
    <div
      className={classnames(
        styles.CircleButton,
        className,
        THEME_SYLES[theme],
        {
          [styles.small]: size === circleButtonKeys.SMALL,
          [styles.large]: size === circleButtonKeys.LARGE
        }
      )}
      style={style}
    >
      {children}
    </div>
  );
}

CircleButton.propTypes = checkProps({
  className: PropTypes.string,
  theme: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  type: PropTypes.oneOf([circleButtonKeys.BUTTON, circleButtonKeys.PLAIN]),
  onClick: PropTypes.func,
  hideFromTab: PropTypes.bool,
  ariaLabel: PropTypes.string
});

CircleButton.defaultProps = {
  type: circleButtonKeys.BUTTON
};

export default memo(CircleButton);
