import innovation from './settings.innovation.js';
import photography from './settings.photography.js';
import protection from './settings.protection.js';
import productivity from './settings.productivity.js';
import travel from './settings.travel.js';
import family from './settings.family.js';
import OS from './settings.OS.js';

import PhotographyImage from '../../assets/images/interests/photography.jpg';
import ProductivityImage from '../../assets/images/interests/productivity.jpg';
import InnovationImage from '../../assets/images/interests/innovation.jpg';
import InnovationImageCentered from '../../assets/images/interests/innovation-centered.jpg';
import TravelImage from '../../assets/images/interests/travel.jpg';
import FamilyImage from '../../assets/images/interests/family.jpg';

import featuresAssets from '@/data/features-assets';

export default {
  photography,
  productivity,
  innovation,
  travel,
  family,
  protection,
  OS
};

export const INTERESTS_IMAGES = {
  photography: PhotographyImage,
  productivity: ProductivityImage,
  innovation: InnovationImage,
  innovationCentered: InnovationImageCentered,
  travel: TravelImage,
  family: FamilyImage,
  protection: featuresAssets.Security.tile
};
