const langs = {
  en_us: {
    key: 'en_us',
    navTitle: 'English'
  },
  en_gb: {
    key: 'en_gb',
    navTitle: 'English'
  },
  en_au: {
    key: 'en_au',
    navTitle: 'English'
  },
  en_sg: {
    key: 'en_sg',
    navTitle: 'English'
  },
  fr_ca: {
    key: 'fr_ca',
    navTitle: 'Français'
  },
  en_ca: {
    key: 'en_ca',
    navTitle: 'English'
  },
  fr_fr: {
    key: 'fr_fr',
    navTitle: 'Français'
  },
  de_de: {
    key: 'de_de',
    navTitle: 'Deutsch'
  },
  ja_jp: {
    key: 'ja_jp',
    navTitle: '日本語'
  },
  zh_tw: {
    key: 'zh_tw',
    navTitle: '中文'
  }
};

module.exports = langs;
