import features from '@/data/features.js';

const category = {
  name: 'default-os',
  key: 'swipe_interest_QR_photography_card_headline',
  body: 'swipe_interest_QR_photography_card_body',
  questionKey: null,
  groups: [
    {
      groupName: 'iOS',
      groupKey: null,
      featureList: [
        features['pro-level-lens'],
        features['new-pixel-experience'],
        features['4k-video'],
        features['google-tensor'],
        features['live-translate'],
        features['helpful-call-features']
      ]
    },
    {
      groupName: 'Pixel',
      groupKey: null,
      featureList: [
        features['new-pixel-experience'],
        features['google-tensor'],
        features['fast-charging-battery'],
        features['helpful-call-features'],
        features['live-translate'],
        features['pro-level-lens']
      ]
    },
    {
      groupName: 'Android',
      groupKey: null,
      featureList: [
        features['new-pixel-experience'],
        features['google-tensor'],
        features['120-hz-dynamic-display'],
        features['fast-charging-battery'],
        features['live-translate'],
        features['pro-level-lens']
      ]
    }
  ]
};

export { category as default };
