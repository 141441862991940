export const DEVICE_VIEWPORTS = {
  desktop: {
    width: {
      min: 1025,
      max: null
    }
  },
  'desktop-narrow': {
    width: {
      min: 1025,
      max: 1399
    }
  },
  'desktop-common': {
    width: {
      min: 1366,
      max: 1439
    }
  },
  tablet: {
    width: {
      min: 728,
      max: 1024
    }
  },
  phone: {
    width: {
      min: 300,
      max: 727
    }
  }
};

export const FEATURE_LIST_SIZE = 6;

export const TRANSITION_ELEMENT_ID_MAP = {
  wrapper: 'wrapper_',
  content: 'content_',
  image: 'image_',
  caption: 'caption_'
};
