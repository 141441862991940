import { numberInRange } from '../utils/basic-functions';
import { DEVICE_VIEWPORTS } from '../settings/settings.ui';

const { phone, tablet, desktop } = DEVICE_VIEWPORTS;
const { width: phoneWidth } = phone;
const { min: minPhone, max: maxPhone } = phoneWidth;
const { width: tabletWidth } = tablet;
const { min: minTablet, max: maxTablet } = tabletWidth;

export const isBreakpointPhone = (windowWidth) =>
  numberInRange({
    min: minPhone,
    max: maxPhone,
    number: windowWidth,
    inclusive: true
  });

export const isBreakpointDesktop = (windowWidth) => windowWidth >= desktop.width.min;

export const isBreakpointTablet = (windowWidth) =>
  numberInRange({
    min: minTablet,
    max: maxTablet,
    number: windowWidth,
    inclusive: true
  });

export const isBreakpointMobile = (windowWidth) =>
  numberInRange({
    min: minPhone,
    max: maxTablet,
    number: windowWidth,
    inclusive: true
  });
