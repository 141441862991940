import React, { memo, useEffect, useCallback, useRef } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import checkProps from '@jam3/react-check-extra-props';
import { useRouter } from 'next/router';

import PageTransition from '../PageTransition/PageTransition';

import useWindowResizeListener from '../../utils/hooks/use-window-resize-listener';
import { checkWebpSupport } from '../../utils/detect';
import useSetLang from '../../utils/hooks/use-set-lang';
import useFitToHeight from '../../utils/hooks/use-fit-to-height';
import useTracking from '../../utils/hooks/use-tracking';
import store from '../../store';

const RotateScreen = dynamic(() => import('../RotateScreen/RotateScreen'), {
  ssr: false
});
const CookieBanner = dynamic(() => import('@/components/CookieBanner/CookieBanner'));

function Layout({ children }) {
  const { detectAndSetPreferredLocale } = useSetLang();
  const { initTracking, trackPageview } = useTracking();
  const initiatedTracking = useRef(false);
  const router = useRouter();

  useFitToHeight();
  useWindowResizeListener();

  const prevRouteSet = store.app((state) => state.prevRouteSet);
  const isWebpSupportedSet = store.app((state) => state.isWebpSupportedSet);
  const loadedGA = store.app((state) => state.loadedGA);
  const displayContent = store.app((state) => state.displayContent);
  const { localeKey } = store.locale((state) => state);
  const textData = store.locale((state) => state.textData);

  const handleRouteChange = useCallback(
    (url) => {
      if (router.asPath !== url) prevRouteSet(router.asPath);
    },
    [prevRouteSet, router.asPath]
  );

  //Initialize tracking
  useEffect(() => {
    if (localeKey && !initiatedTracking.current) {
      initiatedTracking.current = true;
      initTracking();
    }
  }, [initTracking, localeKey]);

  //Per-page tracking
  useEffect(() => {
    if (router.pathname && localeKey && loadedGA) {
      trackPageview(router.pathname);
    }
  }, [router.pathname, trackPageview, localeKey, loadedGA]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, handleRouteChange]);

  useEffect(() => {
    checkWebpSupport('lossy', (isSupported) => isWebpSupportedSet(isSupported));
  }, [isWebpSupportedSet]);

  useEffect(() => {
    detectAndSetPreferredLocale();
  }, [detectAndSetPreferredLocale]);

  return (
    <>
      {localeKey && displayContent && textData && (
        <>
          <CookieBanner />
          <PageTransition isActive={true} location={router.pathname}>
            {children}
          </PageTransition>
          <RotateScreen />
        </>
      )}
    </>
  );
}

Layout.propTypes = checkProps({
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
});

Layout.defaultProps = {};

export default memo(Layout);
