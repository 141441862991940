import React, { useState, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useImagePreloaderWorker from './ImagePreloader.worker';

import styles from './ImagePreloader.module.scss';

const ImagePreloader = React.forwardRef((props, ref) => {
  const {
    src,
    aspectRatio,
    className,
    width,
    height,
    fit,
    noFade,
    fadeSpeed,
    withWorker,
    alt
  } = props;

  const [loaded, setLoaded] = useState(false);
  const { preloadedImage, loadImageWithWorker, workerImagePreloadError } =
    useImagePreloaderWorker(src);
  const imgElRef = useRef(null);

  useEffect(() => {
    if (withWorker) {
      loadImageWithWorker();
    }
  }, [src, withWorker, loadImageWithWorker]);

  const handleOnLoad = () => {
    setLoaded(true);
  };

  const classNames = useMemo(() => {
    return classnames(
      styles['ImagePreloader'],
      styles[`ImagePreloader-fade--${noFade ? 'disabled' : fadeSpeed}`],
      { [styles['ImagePreloader--loaded']]: loaded },
      className
    );
  }, [noFade, fadeSpeed, className, loaded]);

  const imgClassNames = useMemo(() => {
    return classnames(
      styles['ImagePreloader__image'],
      styles[`ImagePreloader__image--${fit}`],
      className
    );
  }, [fit, className]);

  const containerStyle = useMemo(() => {
    return {
      ...(width && { width }),
      ...(height && { height })
    };
  }, [width, height]);

  const imageSource = useMemo(() => {
    if (!withWorker || workerImagePreloadError) {
      return src;
    } else if (withWorker && preloadedImage) {
      return preloadedImage;
    }
  }, [withWorker, preloadedImage, workerImagePreloadError, src]);

  const mountImage = useMemo(() => {
    if (!withWorker || workerImagePreloadError) {
      return true;
    } else if (withWorker && preloadedImage) {
      return true;
    }
    return false;
  }, [withWorker, preloadedImage, workerImagePreloadError]);

  useEffect(() => {
    // Make sure to set loaded to true if img.complete is true
    // THis happens when the image is cached in the browser
    if (imgElRef && imgElRef.current) {
      if (imgElRef.current.complete && !loaded) {
        setLoaded(true);
      }
    }
  }, [mountImage, loaded, setLoaded]);

  const imgStyle = (() => {
    if (aspectRatio) {
      return {
        position: 'absolute',
        width: '100%',
        height: '100%'
      };
    } else if (height === 'auto') {
      return {
        position: 'relative',
        height: 'auto'
      };
    }
    return {};
  })();

  return (
    <div ref={ref} className={classNames} style={containerStyle}>
      {aspectRatio && (
        <div className={styles.aspectRatioContainer} style={{ width }}>
          <div
            className={styles.aspectRatioContainerInner}
            style={{ paddingTop: `${100 * aspectRatio}%` }}
          ></div>
        </div>
      )}
      {mountImage && (
        <img
          ref={imgElRef}
          style={imgStyle}
          className={imgClassNames}
          src={imageSource}
          onLoad={handleOnLoad}
          alt={alt}
        />
      )}
    </div>
  );
});

ImagePreloader.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  aspectRatio: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  alt: PropTypes.string,
  fit: PropTypes.oneOf(['cover', 'contain', 'fill', 'scale-down']),
  noFade: PropTypes.bool,
  fadeSpeed: PropTypes.oneOf(['normal', 'slow', 'fast']),
  withWorker: PropTypes.bool
};

ImagePreloader.defaultProps = {
  src: '',
  className: '',
  aspectRatio: null,
  width: '',
  height: 'auto',
  alt: '',
  fit: 'cover',
  noFade: false,
  fadeSpeed: 'fast',
  withWorker: true
};

export default ImagePreloader;
