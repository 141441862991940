import create from 'zustand';
import langs from '../settings/settings.languages';

const getLocalesUrl = (locale) => `/assets/translations/${locale}.json`;

const getLocaleData = async (languageKey) => {
  try {
    const response = await fetch(getLocalesUrl(languageKey));
    const data = await response.json();
    return data;
  } catch (err) {
    return console.error(err);
  }
};

const useLocaleStore = create((set) => {
  return {
    localeKey: langs.en_us.key,
    setLocaleKey: async (localeKey) => {
      const textData = await getLocaleData(localeKey);
      set(() => ({
        textData: {
          ...textData
        },
        localeKey
      }));
    },
    textData: null
  };
});

export default useLocaleStore;
