export const LOCAL_STORAGE_KEY = {
  PIXEL: 'PIXEL'
};

export const localStorageState = {
  lang: null
};

const complexLocalStorage = {
  remove: (key) => {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      // no local storage (ssr or incognito mode)
      return undefined;
    }
  },
  get: (key) => {
    try {
      const serializedData = localStorage.getItem(key);
      if (serializedData === null) {
        return undefined;
      }
      return JSON.parse(serializedData);
    } catch (e) {
      // no local storage (ssr or incognito mode)
      return undefined;
    }
  },
  set: (key, val) => {
    try {
      val && localStorage.setItem(key, JSON.stringify(val));
      return true;
    } catch (e) {
      // no local storage (ssr or incognito mode)
      return false;
    }
  },
  init: () => {
    try {
      const serializedData = localStorage.getItem(LOCAL_STORAGE_KEY.PIXEL);

      if (serializedData === null) {
        localStorage.setItem(LOCAL_STORAGE_KEY.PIXEL, JSON.stringify(localStorageState));
      }

      return true;
    } catch (e) {
      // no local storage (ssr or incognito mode)
      return false;
    }
  }
};

complexLocalStorage.init();

export default complexLocalStorage;
