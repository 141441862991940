import mediaTypeKeys from '@/keys/mediaTypes';
import featuresAssets from '@/data/features-assets';

// id should be the same as property key
const allFeatures = {
  'pro-level-lens': {
    id: 'pro-level-lens',
    title: 'features_QR_RA_only_item_1',
    headlineKey: 'feature_pro_level_QR_headline',
    bodyKey: 'feature_pro_level_QR_body',
    src: featuresAssets.ProLevelLens.slider,
    squareImage: featuresAssets.ProLevelLens.tile,
    mediaType: mediaTypeKeys.IMAGE,
    pixel6ProOnly: true,
    pixel6ProOnlyCopyKey: 'feature_pro_level_RA5_only_tag',
    topCaption: 'feature_pro_level_RA5_only_tag',
    type: 'feature'
  },
  zoom: {
    id: 'zoom',
    title: 'features_QR_RA_only_item_2',
    headlineKey: 'feature_zoom_headline',
    bodyKey: 'feature_zoom_body',
    src: featuresAssets.Zoom.slider,
    squareImage: featuresAssets.Zoom.tile,
    mediaType: mediaTypeKeys.VIDEO_MP4,
    pixel6ProOnly: true,
    pixel6ProOnlyCopyKey: 'zoom_RA5_only_tag',
    topCaption: 'zoom_RA5_only_tag',
    type: 'feature'
  },
  'ultrawide-selfies': {
    id: 'ultrawide-selfies',
    title: 'features_QR_RA_only_item_3',
    headlineKey: 'feature_ultrawide_headline',
    bodyKey: 'feature_ultrawide_body',
    src: featuresAssets.Selfies.slider,
    squareImage: featuresAssets.Selfies.tile,
    mediaType: mediaTypeKeys.IMAGE,
    pixel6ProOnly: true,
    pixel6ProOnlyCopyKey: 'feature_ultrawide_RA5_only_tag',
    topCaption: 'feature_ultrawide_RA5_only_tag',
    type: 'feature'
  },
  '4k-video': {
    id: '4k-video',
    title: 'features_QR_RA_only_item_4',
    headlineKey: 'feature_4K_video_QR_headline',
    bodyKey: 'featire_4K_video_QR_body',
    src: featuresAssets._4k.slider,
    squareImage: featuresAssets._4k.tile,
    mediaType: mediaTypeKeys.IMAGE,
    pixel6ProOnly: true,
    pixel6ProOnlyCopyKey: 'feature_4K_video_RA5_only_tag',
    topCaption: 'feature_4K_video_RA5_only_tag',
    type: 'feature'
  },
  'motion-mode': {
    id: 'motion-mode',
    title: 'all_features_page_QR_button_3',
    headlineKey: 'motion_mode_QR_title',
    bodyKey: 'motion_mode_QR_copy',
    src: featuresAssets.MotionMode.slider,
    squareImage: featuresAssets.MotionMode.tile,
    mediaType: mediaTypeKeys.VIDEO_MP4,
    type: 'feature'
  },
  'real-tone': {
    id: 'real-tone',
    title: 'all_features_page_QR_button_6',
    headlineKey: 'feature_douglass_headline',
    bodyKey: 'feature_douglass_body',
    src: featuresAssets.RealTone.slider,
    squareImage: featuresAssets.RealTone.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'ultrawide-lens': {
    id: 'ultrawide-lens',
    title: 'all_features_page_QR_button_7',
    headlineKey: 'feature_ultrawide_lens_QR_headline',
    bodyKey: 'feature_ultrawide_lens_QR_body',
    src: featuresAssets.Ultrawide.slider,
    squareImage: featuresAssets.Ultrawide.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'magic-eraser': {
    id: 'magic-eraser',
    title: 'all_features_page_QR_button_8',
    headlineKey: 'feature_magic_eraser_QR_headline',
    bodyKey: 'feature_magic_eraser_QR_body',
    src: featuresAssets.MagicEraser.slider,
    squareImage: featuresAssets.MagicEraser.tile,
    mediaType: mediaTypeKeys.VIDEO_MP4,
    type: 'feature'
  },
  'speech-enhancement': {
    id: 'speech-enhancement',
    title: 'all_features_page_QR_button_23',
    headlineKey: 'feature_speech_enhancement_QR_headline',
    bodyKey: 'feature_speech_enhancement_QR_body',
    src: featuresAssets.Speech.slider,
    squareImage: featuresAssets.Speech.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'bigger-sensors': {
    id: 'bigger-sensors',
    title: 'all_features_page_QR_button_9',
    headlineKey: 'feature_bigger_sensors_QR_headline',
    bodyKey: 'feature_bigger_sensors_QR_body',
    src: featuresAssets.Sensors.slider,
    squareImage: featuresAssets.Sensors.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'new-pixel-experience': {
    id: 'new-pixel-experience',
    title: 'all_features_page_QR_button_10',
    headlineKey: 'new_pixel_experience_QR_headline',
    bodyKey: 'new_pixel_experience_QR_body',
    src: featuresAssets.NewPixelExperience.slider,
    squareImage: featuresAssets.NewPixelExperience.tile,
    mediaType: mediaTypeKeys.VIDEO_MP4,
    type: 'feature'
  },
  'google-tensor': {
    id: 'google-tensor',
    title: 'all_features_page_QR_button_11',
    headlineKey: 'feature_tensor_QR_headline',
    bodyKey: 'feature_tensor_QR_body',
    src: featuresAssets.Tensor.slider,
    squareImage: featuresAssets.Tensor.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'helpful-call-features': {
    id: 'helpful-call-features',
    title: 'all_features_page_QR_button_24',
    headlineKey: 'feature_helpful_call_features_QR_headline',
    bodyKey: 'feature_helpful_call_features_QR_body',
    src: featuresAssets.CallFeatures.slider,
    squareImage: featuresAssets.CallFeatures.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'live-space': {
    id: 'live-space',
    title: 'all_features_page_QR_button_12',
    headlineKey: 'feature_live_space_QR_headline',
    bodyKey: 'feature_live_space_QR_body',
    src: featuresAssets.LiveSpace.slider,
    squareImage: featuresAssets.LiveSpace.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'live-translate': {
    id: 'live-translate',
    title: 'all_features_page_QR_button_13',
    headlineKey: 'feature_live_translate_QR_headline',
    bodyKey: 'feature_live_translate_QR_subhead',
    src: featuresAssets.LiveTranslate.slider,
    squareImage: featuresAssets.LiveTranslate.tile,
    mediaType: mediaTypeKeys.VIDEO_MP4,
    type: 'feature'
  },
  'voice-typing': {
    id: 'voice-typing',
    title: 'all_features_page_QR_button_14',
    headlineKey: 'feature_voice_typing_QR_headline',
    bodyKey: 'feature_voice_typing_QR_body',
    src: featuresAssets.Voice.slider,
    squareImage: featuresAssets.Voice.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  '120-hz-dynamic-display': {
    id: '120-hz-dynamic-display',
    title: 'all_features_page_QR_button_15',
    headlineKey: 'feature_120_hz_QR_headline',
    bodyKey: 'feature_120_hz_QR_body',
    src: featuresAssets._120hz.slider,
    squareImage: featuresAssets._120hz.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'fast-charging-battery': {
    id: 'fast-charging-battery',
    title: 'all_features_page_QR_button_16',
    headlineKey: 'feature_fast_charging__QR_headline',
    bodyKey: 'feature_fast_charging_QR_body',
    src: featuresAssets.Battery.slider,
    squareImage: featuresAssets.Battery.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  '5-years-of-updates': {
    id: '5-years-of-updates',
    title: 'all_features_page_QR_button_17',
    headlineKey: 'feature_5_years_updates_QR_headline',
    bodyKey: 'feature_5_years_updates_QR_body',
    src: featuresAssets._5YearsUpdates.slider,
    squareImage: featuresAssets._5YearsUpdates.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'anti-virus-anti-phishing': {
    id: 'anti-virus-anti-phishing',
    title: 'all_features_page_QR_button_18',
    headlineKey: 'feature_anti_virus_anti_phishing_QR_headline',
    bodyKey: 'feature_anti_virus_anti_phishing_QR_body',
    src: featuresAssets.AntiVirus.slider,
    squareImage: featuresAssets.AntiVirus.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'personal-safety': {
    id: 'personal-safety',
    title: 'all_features_page_QR_button_25',
    headlineKey: 'feature_personal_safety_QR_headline',
    bodyKey: 'feature_personal_safety_QR_body',
    src: featuresAssets.Safety.slider,
    squareImage: featuresAssets.Safety.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  '5g': {
    id: '5g',
    title: 'all_features_page_QR_button_19',
    headlineKey: 'feature_5G_QR_headline',
    bodyKey: 'feature_5G_QR_body',
    src: featuresAssets._5g.slider,
    squareImage: featuresAssets._5g.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'feature-drops': {
    id: 'feature-drops',
    title: 'all_features_page_QR_button_20',
    headlineKey: 'feature_drops_QR_headline',
    bodyKey: 'feature_drops_QR_body',
    src: featuresAssets.FeatureDrops.slider,
    squareImage: featuresAssets.FeatureDrops.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'security-hub': {
    id: 'security-hub',
    title: 'all_features_page_QR_button_21',
    headlineKey: 'feature_security_hub_headline',
    bodyKey: 'feature_security_hub_body',
    src: featuresAssets.Security.slider,
    squareImage: featuresAssets.Security.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  },
  'mic-cam-toggle': {
    id: 'mic-cam-toggle',
    title: 'all_features_page_QR_button_22',
    headlineKey: 'feature_mic_cam_toggle_QR_headline',
    bodyKey: 'feature_mic_cam_toggle_QR_body',
    src: featuresAssets.MicCamToggle.slider,
    squareImage: featuresAssets.MicCamToggle.tile,
    mediaType: mediaTypeKeys.IMAGE,
    type: 'feature'
  }
};

// excludes certains features from certain locales
export const FEATURE_LOCALE_EXCLUSION_MAP = {
  'helpful-call-features': ['en_gb', 'en_sg', 'en_au', 'zh_tw'],
  'live-space': ['en_au']
};

export default allFeatures;
