import { useRouter } from 'next/router';
import store from '../../store';
import langs from '../../settings/settings.languages';
import { getRouteTrackingKeyByUrl } from '../basic-functions';

const useRouteChecker = () => {
  const router = useRouter();
  const { localeKey } = store.locale((state) => state);
  const disableAccessories = [langs.en_au.key, langs.en_sg.key, langs.zh_tw.key].includes(localeKey);
  const routeSlug = getRouteTrackingKeyByUrl(router.pathname);

  return {
    disableAccessories,
    routeSlug
  };
};

export default useRouteChecker;
