import features from '@/data/features.js';

const category = {
  name: 'family',
  key: 'swipe_interest_QR_family_card_headline',
  body: 'swipe_interest_QR_family_card_body',
  questionKey: null,
  groups: [
    {
      groupName: 'default',
      groupKey: null,
      featureList: [
        features['google-tensor'],
        features['120-hz-dynamic-display'],
        features['feature-drops'],
        features['fast-charging-battery'],
        features['helpful-call-features'],
        features['pro-level-lens']
      ]
    }
  ]
};

export { category as default };
