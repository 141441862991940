export default {
  Landing: {
    path: '/',
    title: 'Welcome',
    trackingKey: 'welcome'
  },
  Results: {
    path: '/results/',
    title: 'Results',
    trackingKey: 'results'
  },
  Accessories: {
    path: '/accessories/',
    title: 'Accessories',
    trackingKey: 'accessories'
  },
  Features: {
    path: '/features/',
    title: 'All Features',
    trackingKey: 'features'
  },
  Specs: {
    path: '/specs/',
    title: 'Tech Specs',
    trackingKey: 'specs'
  },
  Switching: {
    path: '/switching/',
    title: 'How To Switch',
    trackingKey: 'switch'
  },
  _404: {
    path: '/404/',
    title: '404',
    trackingKey: '404'
  }
};
