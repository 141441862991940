import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkProps from '@jam3/react-check-extra-props';
import NextHead from 'next/head';
import { useRouter } from 'next/router';

import { siteName, siteSlogan } from '../../settings/settings.global';
import useCopy from '@/utils/hooks/use-copy';

import DefaultShareImageTwitter from '@/assets/images/share-image-twitter.jpeg';
import DefaultShareImageFacebook from '@/assets/images/share-image-facebook.jpeg';

const TITLE_SEPARATOR = '|';

function Head({ title, description, keywords }) {
  const router = useRouter();
  const copy = useCopy();

  const ogUrl = `${process.env.NEXT_PUBLIC_WEBSITE_SITE_URL}${router.asPath}`;
  // const ogDefaultImage = `${process.env.NEXT_PUBLIC_WEBSITE_SITE_URL}/assets/images/share-image.jpeg`;
  const ogDefaultImageTwitter = DefaultShareImageTwitter;
  const ogDefaultImageFacebook = DefaultShareImageFacebook;

  const fullTitle = title
    ? `${copy(title, true)} ${TITLE_SEPARATOR} ${siteName}`
    : `${siteName} ${TITLE_SEPARATOR} ${siteSlogan}`;

  const domain = typeof window !== 'undefined' ? window.location.host : '';

  //
  if (typeof window !== 'undefined') {
    if (!window.gtag) {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', process.env.NEXT_PUBLIC_ANALYTICS_ID);
    }
  }

  return (
    <NextHead>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join()} />
      {/* Generate favicons in https://realfavicongenerator.net */}
      <meta name="theme-color" content="#ffffff" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <link rel="apple-touch-icon" sizes="180x180" href="/fav/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/fav/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/fav/favicon-16x16.png" />
      <link rel="manifest" href="/fav/site.webmanifest" crossOrigin="use-credentials" />
      <link rel="mask-icon" href="/fav/safari-pinned-tab.svg" color="#000000" />
      <link rel="shortcut icon" href="/fav/favicon.ico" />
      <meta name="msapplication-config" content="/fav/browserconfig.xml" />
      {/* Share meta tags */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image" content={ogDefaultImageFacebook} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={ogDefaultImageTwitter} />
      <meta property="twitter:domain" content={domain} />
      <meta property="twitter:url" content={ogUrl} />
      <meta property="twitter:title" content={fullTitle} />
      <meta property="twitter:description" content={description} />

      <meta property="fb:app_id" content="FB_APP_ID" />
      <meta name="google-site-verification" content="[Google Web Master Tools]" />
      <meta name="msvalidate.01" content="[Bing Web Master Tools]" />
      {/* Other recommends */}
      <link rel="canonical" href={ogUrl} />
      {process.env.NEXT_PUBLIC_DNS_PREFETCH && <link rel="dns-prefetch" href={process.env.NEXT_PUBLIC_DNS_PREFETCH} />}

      <script
        async="true"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_ANALYTICS_ID}`}
      />
    </NextHead>
  );
}

Head.propTypes = checkProps({
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.array
});

Head.defaultProps = {
  description: 'Default Description',
  keywords: ['']
};

export default memo(Head);
