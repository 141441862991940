import { mountStoreDevtool } from 'simple-zustand-devtools';
import useLocaleStore from './locale';
import useAppStore from './app';
import useWindowSizeStore from './window-size';
import useWeightedFeatureStore from './features';
import useNavStore from './nav';
import { DEBUG_MODE, IS_DEV } from '../settings/settings.params';

export { useLocaleStore, useWindowSizeStore, useAppStore };

export default {
  app: useAppStore,
  locale: useLocaleStore,
  windowSize: useWindowSizeStore,
  weightedFeature: useWeightedFeatureStore,
  nav: useNavStore
};

if (typeof window !== 'undefined') {
  window.addEventListener('DOMContentLoaded', () => {
    if (IS_DEV || DEBUG_MODE) {
      // Using multiple store devtools
      // https://github.com/dkgrieshammer/simple-zustand-devtools
      const addStoreDevtool = (name, store) => {
        const separateRoot = document.createElement('div');
        separateRoot.id = `devtools-${name}`;
        document.body.appendChild(separateRoot);
        mountStoreDevtool(name, store, separateRoot);
      };
      addStoreDevtool('AppStore', useAppStore);
      addStoreDevtool('WindowSizeStore', useWindowSizeStore);
      addStoreDevtool('LocaleStore', useLocaleStore);
    }
  });
}
