import classnames from 'classnames';

import styles from './use-cjk-styles.module.scss';
import { digitFromSuperscript } from '../copy-normalize';
import sanitizer from '../sanitizer';

import { useLocaleStore } from '@/store';
import langs from '@/settings/settings.languages';

export const CLASSNAME_WRAPPER = 'copyWrap';
const CLASSNAME_CJK = 'lang--cjk';

const useCJKStyles = () => {
  const { localeKey } = useLocaleStore();
  const cjkLocales = [langs.ja_jp.key, langs.zh_tw.key];
  const isCJK = cjkLocales.includes(localeKey);
  const cjkClassName = isCJK ? CLASSNAME_CJK : '';

  const parseHtmlForEng = (s) => {
    function entityForSymbolInContainer(s) {
      var code = s.charCodeAt(0);
      var codeHex = code.toString(16).toUpperCase();
      while (codeHex.length < 4) {
        codeHex = '0' + codeHex;
      }
      return codeHex;
    }

    function is_latin_english(s) {
      if (
        entityForSymbolInContainer(s) >= '0020' &&
        entityForSymbolInContainer(s) <= '007F'
      ) {
        return true;
      } else {
        return false;
      }
    }

    var s_split = s.split('');

    var s_result = [];
    s_result.push(s_split[0]);
    for (var i = 1; i < s_split.length; i++) {
      if (
        is_latin_english(s_result[s_result.length - 1]) ===
        is_latin_english(s_split[i])
      ) {
        s_result[s_result.length - 1] += s_split[i];
      } else {
        s_result.push(s_split[i]);
      }
    }

    const regExpAlphabet = /[a-zA-Z]/g;
    const regExpNumbers = /^\d+$/;
    const html = s_result.map((str, i) => {
      if (
        regExpAlphabet.test(str) ||
        regExpNumbers.test(parseInt(str.trim()))
      ) {
        return (
          <span key={i} className={styles.breakAuto}>
            {str}
          </span>
        );
      } else {
        return str;
      }
    });
    return html;
  };

  const handleBreaks = (s) => {
    // Return if no string
    if (!s) return;

    // Return with HTML line breaks
    if (s.includes('\n')) {
      const splitByLineBreaks = s.split('\n');
      const finalHtml = splitByLineBreaks.map((line, i) => (
        <>
          <span key={i}>{parseHtmlForEng(line)}</span>
          {i !== splitByLineBreaks.length - 1 && <br key={`${i}-br`} />}
        </>
      ));

      return finalHtml;
    }

    // Return default string if not CJK
    if (!isCJK) return s;

    // Continue on parsing if CJK....
    return parseHtmlForEng(s);
  };

  const CopyWrapper = ({ text, disableParsing }) => {
    let formatted = disableParsing ? text : handleBreaks(text);
    let superscripted = formatted;
    const isObject = typeof formatted === 'object';

    const superscripts = /[\u2070-\u209F\u00B2\u00B3\u00B9]+/;
    let superscriptMatch = null;

    if (typeof formatted === 'string') {
      superscriptMatch = formatted.match(superscripts);
      if (superscriptMatch) {
        superscripted = superscripted.split(superscriptMatch[0]);
        superscripted = superscripted.join(
          `<span>${digitFromSuperscript(superscriptMatch[0])}</span>`
        );
      }
    } else if (isObject) {
      // for (let i = 0; i < formatted.length; i++) {
      //   if (typeof formatted[i] === 'string') {
      //     superscriptMatch = formatted[i].match(superscripts);
      //     if (superscriptMatch) {
      //       console.log(superscriptMatch[0]);
      //       formatted[i] = formatted[i].split(superscriptMatch[0]);
      //       formatted[i] = formatted[i].join(
      //         `<span>${digitFromSuperscript(superscriptMatch[0])}</span>`
      //       );
      //     }
      //   }
      // }
      // superscriptMatch = false;
    }

    return superscriptMatch ? (
      <span
        className={classnames(
          cjkClassName,
          CLASSNAME_WRAPPER,
          styles.superscripted
        )}
        dangerouslySetInnerHTML={{
          __html: sanitizer(isObject ? formatted : superscripted)
        }}
      />
    ) : (
      <span className={classnames(cjkClassName, CLASSNAME_WRAPPER)}>
        {formatted}
      </span>
    );
  };

  return {
    cjkClassName,
    CopyWrapper,
    handleBreaks
  };
};

export default useCJKStyles;
