import { useEffect } from 'react';
import { useWindowSize } from '@jam3/react-hooks';
import { resizeDebounceTime } from '../../settings/settings.global';

/**
 *
 */
function useFitToHeight() {
  const { innerHeight } = useWindowSize(resizeDebounceTime);

  useEffect(() => {
    let timeout;

    const update = () => {
      timeout = setTimeout(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }, 100); // some browsers are just buggy and need some time to update window.innerHeight...
    };

    update();
    document.addEventListener('visibilitychange', update);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('visibilitychange', update);
    };
  }, [innerHeight]);

  return null;
}

export default useFitToHeight;
