import detect from '../utils/detect';

const designWidthPhone = 411;
const maxWidth = 768;

export default function getGlobalREMValue(windowWidth) {
  let root = document.documentElement;

  // Set fluid rem unit only if on mobile
  // Or if it's mobile but landscape mode
  let val = `${windowWidth / designWidthPhone}px`;
  if (windowWidth >= maxWidth || (detect.device.isPhone && window.innerWidth > window.innerHeight)) {
    val = '1px';
  }

  root.style.setProperty('--rem-pixel', val);
}
