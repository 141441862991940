import create from 'zustand';

const useAppStore = create((set) => {
  return {
    isWebpSupported: false,
    isWebpSupportedSet: (val) => set(() => ({ isWebpSupported: val })),
    prevRoute: '',
    prevRouteSet: (val) => set(() => ({ prevRoute: val })),
    // Nav Overlay
    isNavOverlayOpen: false,
    isNavOverlayOpenSet: (val) => set(() => ({ isNavOverlayOpen: val })),
    showContent: false,
    setShowContent: (val) => set(() => ({ showContent: val })),
    showLoader: true,
    setShowLoader: (val) => set(() => ({ showLoader: val })),
    swipePromptAlreadyShown: false,
    setSwipePromptAlreadyShown: (val) => set(() => ({ swipePromptAlreadyShown: val })),
    showSwipePrompt: false,
    setShowSwipePrompt: (val) => set(() => ({ showSwipePrompt: val })),
    //Refreshing content
    displayContent: true,
    setDisplayContent: (val) => set(() => ({ displayContent: val })),
    introAnimationEnded: false,
    setIntroAnimationEnded: (val) => set(() => ({ introAnimationEnded: val })),
    loadedGA: false,
    setLoadedGA: (val) => set(() => ({ loadedGA: val })),
    initialHeight: 0,
    setInitialHeight: (val) => set(() => ({ initialHeight: val })),
    safariBottomBarShown: true,
    setSafariBottomBarShown: (val) => set(() => ({ safariBottomBarShown: val }))
  };
});

export default useAppStore;
