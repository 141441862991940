import { useEffect } from 'react';
import _throttle from 'lodash/throttle';
import useWindowSizeStore from '../../store/window-size';
import setGlobalREMValue from '../../utils/set-global-rem-value';
import setGlobalVhValue from '../../utils/set-global-vh-value';

const useWindowResizeListener = () => {
  const setWindowSize = useWindowSizeStore((state) => state.setWindowSize);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.screen.width;
      const windowHeight = window.innerHeight;

      setGlobalREMValue(windowWidth);
      setGlobalVhValue(windowHeight);

      setWindowSize({
        width: windowWidth,
        height: windowHeight
      });
    };

    handleResize();

    const throttledHandleResize = _throttle(handleResize, 100, {
      trailing: true
    });
    window.addEventListener('resize', throttledHandleResize);

    return () => window.removeEventListener('resize', throttledHandleResize);

    // eslint-disable-next-line
  }, []);
};

export default useWindowResizeListener;
