import features from '@/data/features.js';

const category = {
  name: 'travel',
  key: 'swipe_interest_QR_travel_card_headline',
  body: 'swipe_interest_QR_travel_card_copy',
  questionKey: null,
  groups: [
    {
      groupName: '',
      groupKey: null,
      featureList: [
        features['live-translate'],
        features['personal-safety'],
        features['fast-charging-battery'],
        features['bigger-sensors'],
        features['motion-mode'],
        features['zoom']
      ]
    }
  ]
};

export { category as default };
