import features from '@/data/features.js';

const category = {
  name: 'protection',
  key: 'swipe_interest_QR_protection_card_headline',
  body: 'swipe_interest_QR_protection_card_body',
  questionKey: 'swipe_interest_QR_protection_question',
  groups: [
    {
      groupName: '',
      groupKey: 'swipe_interest_QR_protection_button_1',
      featureList: [
        features['security-hub'],
        features['5-years-of-updates'],
        features['google-tensor'],
        features['anti-virus-anti-phishing'],
        features['120-hz-dynamic-display'],
        features['fast-charging-battery']
      ]
    }
  ]
};

export { category as default };
