import { useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import routes from '../../settings/settings.routes';
import trackingKeys from '../../keys/tracking';
import store from '../../store';
import detect from '../detect';
import events from '../../data/tracking-events';
import useRouteChecker from '../../utils/hooks/use-route-checker';

const useTracking = () => {
  const { routeSlug } = useRouteChecker();
  const prevPage = useRef(null);
  const { localeKey: currentLocaleKey } = store.locale((state) => state);
  const { setLoadedGA } = store.app((state) => state);
  const router = useRouter();
  const currentPath = useRef(null);

  useEffect(() => {
    currentPath.current = router.pathname;
  }, [router.pathname]);

  const logEvent = (action = false, payload = {}) => {
    if (typeof window !== 'undefined' && action) {
      if (!window.gtag) return console.warn(`window.gtag does not exist (attempting tracking action ${action}).`);

      const data = {
        event_category: payload.category ? payload.category : '',
        event_label: payload.label ? payload.label : ''
      };

      window.gtag('event', action, data);
    }
  };

  const trackUserConfig = () => {
    const dimensions = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    //TODO: Fill out CITY and INTERNET_CONNECTION
    const userConfig = {
      id: 'e-config',
      event: 'General Config Event',
      category: trackingKeys.category.config,
      action: trackingKeys.actions.log,
      label: `${currentLocaleKey} - [CITY] - ${detect?.os?._parsedOSName} ${detect?.browser?._parsedBrowserName} - ${
        dimensions.width
      }x${dimensions.height} - ${(dimensions.width / dimensions.height).toFixed(1)} - [INTERNET_CONNECTION]`
    };

    logEvent(userConfig.action, userConfig);
  };

  const initTracking = () => {
    const initTrackingInterval = setInterval(() => {
      if (window.gtag) {
        window.clearInterval(initTrackingInterval);
        setLoadedGA(true);
        trackUserConfig();
      }
    }, 300);

    setTimeout(() => {
      window.clearInterval(initTrackingInterval);
    }, 10000);
  };

  const trackPageview = (route) => {
    if (route !== prevPage.current) {
      prevPage.current = route;
      const isHome = route === '/';

      let routeObj = isHome
        ? routes.Landing
        : Object.values(routes).filter((routeObj) => routeObj.path === `${route}/`)[0];

      //Could be any random url / 404
      if (!routeObj) {
        routeObj = routes._404;
      }

      window.gtag('event', 'page_view', {
        page_title: `${currentLocaleKey} ${routeObj.title}`,
        page_path: route
      });
    }
  };

  const trackEvent = (eventKey, data) => {
    const eventData = events[eventKey];
    if (!eventData) return console.warn(`No event with key ${eventKey} exists.`);

    let filledData = {};
    Object.values(eventData).forEach((value, i) => {
      if (value.includes('[LOCALE]')) {
        value = value.replace('[LOCALE]', currentLocaleKey);
      }

      if (value.includes('[CURRENT_URL]')) {
        value = value.replace('[CURRENT_URL]', currentPath.current);
      }

      if (value.includes('[PAGE]')) {
        value = value.replace('[PAGE]', routeSlug);
      }

      if (data) {
        Object.keys(data).forEach((key, i) => {
          if (value.includes(`[${key}]`)) {
            value = value.replace(`[${key}]`, Object.values(data)[i]);
          }
        });
      }

      const key = Object.keys(eventData)[i];
      filledData[key] = value;
    });

    logEvent(filledData.action, filledData);
  };

  return { initTracking, trackPageview, trackEvent };
};

export default useTracking;
