import features from '@/data/features.js';

const category = {
  name: 'productivity',
  key: 'swipe_interest_QR_productivity_card_headline',
  body: 'swipe_interest_QR_productivity_card_body',
  questionKey: null,
  groups: [
    {
      groupName: '',
      groupKey: null,
      featureList: [
        features['new-pixel-experience'],
        features['fast-charging-battery'],
        features['feature-drops'],
        features['5g'],
        features['voice-typing'],
        features['google-tensor']
      ]
    }
  ]
};

export { category as default };
