import features from '@/data/features.js';

const category = {
  name: 'innovation',
  key: 'swipe_interest_QR_innovation_card_headline',
  body: 'swipe_interest_QR_innovation_card_body',
  questionKey: 'swipe_interest_QR_innovation_question',
  groups: [
    {
      groupName: '',
      groupKey: 'swipe_interest_QR_innovation_button_1',
      featureList: [
        features['new-pixel-experience'],
        features['google-tensor'],
        features['helpful-call-features'],
        features['live-space'],
        features['live-translate'],
        features['feature-drops']
      ]
    }
  ]
};

export { category as default };
